<template>
  <div class="home">
    <main-menu class="z-30"></main-menu>
    <!-- hero -->
    <landing-hero></landing-hero>

    <!-- highlighted news -->
    <news-highlight></news-highlight>

    <news-list></news-list>

    <!-- instagram feed -->
    <instagram-gallery></instagram-gallery>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
    MainMenu: () => import("@/components/MainMenu.vue"),
    NewsHighlight: () => import("@/components/Home/NewsHighlight.vue"),
    NewsList: () => import("@/components/Home/NewsList.vue"),
    InstagramGallery: () => import("@/components/Home/InstagramGallery.vue"),
    LandingHero: () => import("@/components/Home/LandingHero.vue"),
  },
};
</script>


